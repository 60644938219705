<template>
  <div
    id="scansione"
    style="display: flex; align-items: center; justify-content: center; height: 100%;"
  >
    <v-card
      class="mx-auto"
      min-width="300px"
      max-width="600px"
      width="55vw"
      style="display: block; text-align: left"
    >
      <v-card-title>
        <v-img :src="$store.state.logo" height="80px" contain />
      </v-card-title>
      <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="login">
        <v-card-text>

          <v-text-field
            v-model="azienda"
            :rules="empty_rule"
            label="Azienda"
            required
          ></v-text-field>

          <v-text-field
            ref="username"
            v-model="username"
            :rules="empty_rule"
            label="Username"
            required
            :maxlength="10"
            counter="10"
          ></v-text-field>

          <v-text-field
            v-model="password"
            :rules="psw_rules"
            label="Password"
            required
            :append-icon="show_psw ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show_psw ? 'text' : 'password'"
            @click:append="show_psw = !show_psw"
            :maxlength="10"
            counter="10"
          ></v-text-field>
        </v-card-text>
        <v-card-actions
          class="pa-4 pt-0 mb-2"
          style="display: block; text-align: center"
        >
          <v-btn
            color="primary"
            width="100%"
            max-width="300px"
            :disabled="!valid || loading"
            :loading="loading"
            type="submit"
          >
            Login
          </v-btn>
          <span v-if="error != ''" class="red--text">{{ error }}</span>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import router from "../router";

export default {
  name: "LoginForm",
  data: () => ({
    error: '',
    valid: false,
    loading: false,
    azienda: '',
    username: '',
    password: '',
    show_psw: false, 
    psw_rules: [
      v => !!v || 'Password errata!',
    ],
    empty_rule: [
      v => !!v || 'Campo obbligatorio!'
    ]
  }),
  created() {
    this.$store.state.logo = localStorage.getItem("logo") ? localStorage.getItem("logo") : require('../assets/logo.jpg');
    this.$store.state.background = localStorage.getItem("background") ? localStorage.getItem("background") : "";

    this.$vuetify.theme.themes.light.primary = localStorage.getItem("color_primary");
    this.$vuetify.theme.themes.light.secondary = localStorage.getItem("color_secondary");

    if(localStorage.getItem('cd_azienda')) {
      this.azienda = localStorage.getItem('cd_azienda')
    }
  },
  methods: {
    login() {
      this.$refs.form.validate();

      this.$nextTick(() => {
        if(this.valid) {
          this.loading = true;

          this.axios
            .post(`${this.$root.endpoint}login-portale`, {
              cd_azienda: this.azienda,
              username: this.username,
              password: this.password
            })
            .then((response) => {
              localStorage.clear();
              localStorage.setItem("jwt", response.data.jwt);
              localStorage.setItem("from_tracking", false);

              this.$store.state.jwt = response.data.jwt
              this.$store.state.cd_azienda = this.azienda
              this.$store.state.username = this.username

              this.$emit("check-jwt")
              router.push('/')
            })
            .catch(() => {
              this.password = "";
              this.$refs.form.validate();
              this.loading = false;
            });
        }
      })
    }
  }
};
</script>