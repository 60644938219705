<template>
  <v-card style="height: 90vh">
    <v-card-text class="ma-0 pa-0 fill-height">
      <v-container class="ma-0 py-0 fill-height" style="max-width: 100%; overflow: hidden;">
        <v-row class="fill-height">
          <v-col cols="6" md="8" lg="9" class="pa-0">
            <v-img
              contain
              :src="photo['link']"
              class="grey darken-3"
              style="width: 100%; height: 90vh;"
            >
            </v-img>
          </v-col>
          <v-col cols="6" md="4" lg="3" class="pa-0">
            <v-card class="ma-0 pa-0 fill-height" flat>
              <v-card-title>DETTAGLIO</v-card-title>
              <v-card-text v-if="dettaglio_barcode != {} && !loading">
                <!--INFO BARCODE-->
                <BarcodeDesc class="mb-4" v-if="photo['ditrec'] != 'A' && !error_barcode" :dett="dettaglio_barcode" :barcode="photo['dibcod']" :ambiente="photo['ditrec']"/>

                <!--INFO FOTO-->
                <span>
                  Scattata da: <b>{{ photo["dicdop"] }}</b>
                  <br />
                </span>
                <span>
                  Data/Ora: <b>{{ photo["didtai"] }} {{ photo["dihmai"] }}</b>
                  <br />
                </span>
                <span>
                  Motivazione: <b>{{ photo["dicavs"] }} - {{ photo["tbdavs"] }}</b>
                  <br />
                </span>
                <span>
                  Note: <b>{{ photo["didesa"] }}</b>
                  <br />
                </span>
                <span v-if="photo['diarxx']">
                  Mail inviata a: <b>{{ photo["diarxx"] }}</b>
                  <br />
                </span>
              </v-card-text>
              <v-card-text class="ma-0 pa-0"  v-if="loading" style="display: flex; height: calc(100% - 128px); justify-content: center; align-items: center;">
                <v-progress-circular
                  size="64"
                  indeterminate
                  color="secondary"
                ></v-progress-circular>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import BarcodeDesc from './BarcodeDesc.vue';

export default {
  props: ['photo'],
  components: {
    BarcodeDesc
  },
  data: () => ({
    error_barcode: false,
    loading: false,
    dettaglio_barcode: {}
  }),
  methods: {
    updateDetail() {
      this.loading = true;
      this.axios
        .post(`${this.$root.endpoint}barcode`, {
          cd_azienda: this.$store.state.cd_azienda,
          barcode: this.photo['dibcod'],
          ambiente: this.photo['ditrec'],
          tipo_barcode: this.photo['ditpbc']
        })
        .then((response) => {
          this.dettaglio_barcode = response.data;
          this.loading = false;
          this.error_barcode = false;
        })
        .catch(() => {
          this.dettaglio_barcode = {}
          this.loading = false;
          this.error_barcode = true;
        });
    },
  },
}
</script>