<template>
  <v-dialog v-model="dialog" persistent width="1000px">
    <v-card>
      <v-card-title>Aggiungi Foto Libere</v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12" md="6" class="pb-0">
              <v-text-field
                v-model="barcode"
                label="Barcode / Identificativo"
                :rules="empty_rule"
              />
            </v-col>
            <v-col cols="12" md="6" class="pb-0">
              <v-select
                v-model="motivazione"
                label="Motivazione"
                no-data-text="Nessuna motivazione disponibile"
                :items="motivazioni"
                :rules="[orMotivOrDesc]"
              />
            </v-col>
            <v-col cols="12" class="pb-0">
              <v-textarea
                v-model="descrizione"
                label="Descrizione"
                :rules="[orMotivOrDesc]"
                :rows="2"
              />
            </v-col>
          </v-row>
          <v-row>
            <!-- Serve per l'input dei file -->
            <input
              ref="uploader"
              class="d-none"
              type="file"
              @change="addPhoto"
              accept=".jpeg,.png,.jpg"
              multiple="multiple" 
            />
            <v-col cols="6" class="py-0">
              <p class="pt-4 mb-2 fake-card-title" style="">Foto</p>
            </v-col>
            <!-- Bottone per aggiungere altre foto -->
            <v-col cols="6" class="d-flex" v-if="photos.length > 0">
              <v-spacer></v-spacer>
              <v-btn
                color="warning"
                depressed
                rounded
                @click="openFileExplorer"
              >
                <span v-if="true">Nuova foto</span>
                <v-icon>mdi-paperclip</v-icon>
              </v-btn>
            </v-col>
            <!-- Bottone per aggiungere la prima foto -->
            <v-col v-if="photos.length == 0" cols="12" class="d-flex align-center justify-center flex-column" style="min-height: 250px;">
              <b style="font-size:15px">Aggiungi la prima foto</b>
              <v-btn
                color="warning"
                depressed
                small
                fab
                @click="openFileExplorer"
              >
                <v-icon>mdi-paperclip</v-icon>
              </v-btn>
            </v-col>
            <v-col v-else cols="12" class="d-flex flex-wrap justify-center pt-2">
              <v-img
                v-for="(photo, i) in photos" 
                :key="photo.key"
                :src="photo.src"
                max-height="200px"
                max-width="200px"
                class="text-right ma-1"
                style="border-radius: 15px;"
              >
                <v-btn 
                  :id="i" 
                  icon 
                  small
                  dark
                  @click="deletePhoto(i)"
                >
                  <v-icon>
                    mdi-close-circle
                  </v-icon>
                </v-btn>
              </v-img>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" @click="closeDialog()">
          Chiudi
        </v-btn>
        <v-btn 
          color="primary" 
          @click="associaImmagini"
          :loading="loading"
          :disabled="!valid || loading || photos.length == 0"
        >
          Conferma
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: "AggiungiFoto",
  props: {
    dialog: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      valid: "",
      barcode: "",
      motivazione: "",
      motivazioni: [],
      descrizione: "",
      photos: [],
      empty_rule: [
        v => !!v || 'Campo obbligatorio!'
      ],
      loading: false,
    };
  },
  mounted() {
    this.getMotivazioni()
  },
  methods: {
    closeDialog() {
      this.$refs.form.reset()
      this.photos = []
      this.$emit("update:dialog", false);
    },
    getMotivazioni() {
      this.axios
        .get(
          `${this.$root.endpoint}motivazioni`
        )
        .then((response) => {
          this.motivazioni = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    openFileExplorer() {
      this.$refs.uploader.click()
    },
    addPhoto(e) {
      if (e.target.files.length > 0) {
        Array.from(e.target.files).forEach(element => {
          var reader = new FileReader();
          reader.readAsDataURL(element);
          reader.onload = () => {
            this.photos.unshift({
              key: new Date().valueOf(),
              src: reader.result,
            })
          };
          reader.onerror = (error) => {
            console.log('Error: ', error);
          };
        });
      }
    },
    deletePhoto(i) {
      this.photos.splice(i, 1);
    },
    associaImmagini(){
      this.$refs.form.validate()
      this.$nextTick(() => {
        if (!this.valid) {
          return
        }
        
        this.loading = true;

        this.axios
          .post(`${this.$root.endpoint}foto`, {
            username: this.$store.state.username,
            cd_azienda: this.$store.state.cd_azienda,
            ambiente: 'Foto libera',
            barcode: this.barcode.trim(),
            motivazione: this.motivazione.trim(),
            descrizione: this.descrizione.trim(),
            tipo_barcode: "A",
            foto: this.photos,
          })
          .then(() => {
            this.loading = false;
            this.closeDialog();
            this.$emit("updatePhotos");
          })
          .catch((error) => {
            console.log(error)
          });

      })
    },
    orMotivOrDesc() {
      return !!this.motivazione || !!this.descrizione ? true : "Inserire o una motivazione o una descrizione"
    }
  }
}
</script>

<style>
.fake-card-title {
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
}
</style>