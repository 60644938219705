import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.config.productionTip = true
Vue.use(VueAxios, axios)

new Vue({
  router,
  store,
  vuetify,
  data: {
    endpoint: 'https://mclick.newtonsrl.eu/',
    //endpoint: 'http://localhost:5000/',
  },
  render: h => h(App)
}).$mount('#app')
