import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#77Af75',
        secondary: '#B0BEC5',
        accent: '#8c9eff'
        //  error: '#da0000'
      }
    }
  }
});
