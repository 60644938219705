<template>
    <div>
        <!-- LOGISTICA O BOLLE -->
        <div v-if="dett['tipo_barcode'] == 'B' || dett['tipo_barcode'] == '' || ['Logistica', 'L'].includes(ambiente)"> 
          <span>
            <b>Barcode: {{ barcode }}</b
            ><br/>
          </span>
          <span>
            Numero documento:
            <b>{{ dett["nr_doc"] }}</b
            ><br />
          </span>
          <span>
            Mittente:
            <b>{{ dett["mittente"] }}</b
            ><br />
          </span>
          <span>
            Località mittente:
            <b
              >{{ dett["loc_mit"] }} ({{
                dett["pv_mit"]
              }})</b
            ><br />
          </span>
          <span>
            Destinatario:
            <b>{{ dett["destinatario"] }}</b
            ><br />
          </span>
          <span>
            Località destinatario:
            <b
              >{{ dett["loc_dest"] }} ({{
                dett["pv_dest"]
              }})</b
            ><br />
          </span>
          <span>
            Colli: <b>{{ dett["colli"] }}</b> Peso:
            <b>{{ dett["peso"] }}</b
            ><br />
          </span>
        </div>

        <!-- LISTA DI CARICO -->
        <div v-if="dett['tipo_barcode'] == 'L'"> 
          <span>
            <b>Numero Lista: 
            {{ dett["nr_lista"] }}</b>
            <br />
          </span>
          <span>
            Data Lista: 
            <b>{{ dett["dt_lista"] }}</b>
            <br />
          </span>
          <span>
            Codice Automezzo: 
            <b>{{ dett["cd_automezzo"] }}</b>
            <br />
          </span>
          <span>
            Descrizione Automezzo: 
            <b>{{ dett["desc_automezzo"] }}</b>
            <br />
          </span>
          <span>
            Targa Automezzo: 
            <b>{{ dett["targa_automezzo"] }}</b>
            <br />
          </span>
          <span>
            Descrizione Autista: 
            <b>{{ dett["desc_autista"] }}</b>
            <br />
          </span>
        </div>

        <!-- BORDEREAU -->
        <div v-if="dett['tipo_barcode'] == 'X'"> 
          <span>
            <b>Numero Bordereau: 
            {{ dett["nr_borde"] }}</b>
            <br />
          </span>
          <span>
            Data Bordereau: 
            <b>{{ dett["dt_borde"] }}</b>
            <br />
          </span>
          <span>
            Instradamento: 
            <b>{{ dett["instradamento"] }}</b>
            <br />
          </span>
          <span>
            Codice Automezzo: 
            <b>{{ dett["cd_automezzo"] }}</b>
            <br />
          </span>
          <span>
            Descrizione Automezzo: 
            <b>{{ dett["desc_automezzo"] }}</b>
            <br />
          </span>
          <span>
            Targa Automezzo: 
            <b>{{ dett["targa_automezzo"] }}</b>
            <br />
          </span>
          <span>
            Descrizione Autista: 
            <b>{{ dett["desc_autista"] }}</b>
            <br />
          </span>
          <span>
            Colli: <b>{{ dett["colli"] }}</b>
            Peso: <b>{{ dett["peso"] }}</b>
            Volume: <b>{{ dett["volume"] }}</b>
            <br />
          </span>
        </div>

        <!-- FOGLI DI VIAGGIO -->
        <div v-if="dett['tipo_barcode'] == 'F'"> 
          <span>
            <b>Numero Viaggio: 
            {{ dett["nr_viaggio"] }}</b>
            <br />
          </span>
          <span>
            Data Viaggio: 
            <b>{{ dett["dt_viaggio"] }}</b>
            <br />
          </span>
          <span>
            Instradamento Partenza: 
            <b>{{ dett["instradamento_part"] }}</b>
            <br />
          </span>
          <span>
            Instradamento Arrivo: 
            <b>{{ dett["instradamento_arrivo"] }}</b>
            <br />
          </span>
          <span>
            Codice Automezzo: 
            <b>{{ dett["cd_automezzo"] }}</b>
            <br />
          </span>
          <span>
            Descrizione Automezzo: 
            <b>{{ dett["desc_automezzo"] }}</b>
            <br />
          </span>
          <span>
            Targa Automezzo: 
            <b>{{ dett["targa_automezzo"] }}</b>
            <br />
          </span>
          <span>
            Descrizione Autista: 
            <b>{{ dett["desc_autista"] }}</b>
            <br />
          </span>
          <span>
            Descrizione Viaggio: 
            <b>{{ dett["desc_viaggio"] }}</b>
            <br />
          </span>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    props: ['dett', 'barcode', 'ambiente'],
    data: () => ({

    }),
    methods: {

    },
  }
  </script>