<template>
  <v-app id="inspire">
    <v-app-bar
      app
      color="primary"
      v-if="show_toolbar"
    >
      <v-toolbar-title
        style="display: flex; flex-direction: row; align-items: center"
      >
        <h2 style="color: white">GESTIONE FOTO mCLICK</h2>
      </v-toolbar-title>

      <template v-slot:extension>
        <v-tabs color="white" v-model="currentPage">
          <v-spacer></v-spacer>
          <v-tab @click="home">Home</v-tab>
          <!-- <v-tab :disabled="checkPage">Mail</v-tab> -->
          <v-tab @click="tracking">Tracking</v-tab>
          <v-tab @click="logout">Logout</v-tab>
        </v-tabs>
      </template>
    </v-app-bar>

    <v-main :style="main_style" v-if="loaded">
      <router-view @check-jwt="checkJWT"/>
    </v-main> 

    <v-overlay
      :absolute="true"
      :value="!loaded || !$store.state.loaded"
      color="rgba(255, 255, 255, 1)"
      opacity="1"
      style="height: 100vh; z-index: 999"
    >
      <v-progress-circular
        indeterminate
        size="64"
        color="secondary"
      ></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
import router from "./router";

export default {
  name: "App",
  data: () => ({
    loaded: false,
    currentPage: "",
    show_toolbar: false
    // checkPage: true,
  }),
  created() {
    // https://mclickimg.newtonsrl.eu/#/login/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoiMjIwQ0xOTTAwMSIsImNkX2F6aWVuZGEiOiJDYWxvbmlncm91cGFnZSJ9.5LM5m-KTTDSVxvfVH5D7c0veWSlMrLimhDHlkp7TI7M
    // localhost:8080/#/login/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoiMjIwQ0xOTTAwMSIsImNkX2F6aWVuZGEiOiJDYWxvbmlncm91cGFnZSJ9.5LM5m-KTTDSVxvfVH5D7c0veWSlMrLimhDHlkp7TI7M
    // JWT Newton: eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoiMjIwQ0xOTTAwMSIsImNkX2F6aWVuZGEiOiJOZXd0b24ifQ.LZt02TyaL5mhlCe5vyud85LDwIsBtnXTJ4GlNfzBKng
    // https://mclickimg.newtonsrl.eu/#/login/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoiMjIwQ0xOTTAwMSIsImNkX2F6aWVuZGEiOiJDYWxvbmlncm91cGFnZSJ9.5LM5m-KTTDSVxvfVH5D7c0veWSlMrLimhDHlkp7TI7M?ambiente=Trasporti&tipo_barcode=X&barcode=2024001000004    
    this.setToolbar();
    
    if (this.$route.name == "Login") {
      if (
        this.$route.params.jwt !== null &&
        this.$route.params.jwt !== undefined &&
        this.$route.params.jwt !== ""
      ) {
        localStorage.clear();
        localStorage.setItem("jwt", this.$route.params.jwt);
        localStorage.setItem("from_tracking", true);
        this.$store.state.jwt = this.$route.params.jwt;
      } else {
        this.logout();
      }

      //  Siccome dopo il checkJWT cambio subito pagina, devo salvarmi i parametri in una variabile per poi inserirli nello storage se il JWT è valido
      var query_da_salvare = this.$route.query

      this.checkJWT()
      .then(() => {
        //  AS400 utilizza questa pagina per inviare anche parametri per precompilare le associazioni delle foto libere
        if (query_da_salvare && query_da_salvare.ambiente && query_da_salvare.tipo_barcode && query_da_salvare.barcode) {
          this.$store.state.compila_associa = {
            selectedAmbient: query_da_salvare.ambiente,
            tipo_barcode: query_da_salvare.tipo_barcode,
            barcode: query_da_salvare.barcode
          }
        }
      })
    } else if (this.$route.name == "MailLogin") {
      if (
        this.$route.query.jwt !== null &&
        this.$route.query.jwt !== undefined &&
        this.$route.query.jwt !== ""
      ) {
        sessionStorage.clear();
        sessionStorage.setItem("jwt", this.$route.query.jwt);
        this.$store.state.jwt = this.$route.query.jwt;
        sessionStorage.setItem("ambiente", this.$route.query.ambiente);
        this.$store.state.ambiente = this.$route.query.ambiente;
        sessionStorage.setItem("documento", this.$route.query.doc);
        this.$store.state.documento = this.$route.query.doc;

        var tipo_barcode = this.$route.query.tipo_barcode ? this.$route.query.tipo_barcode : "";
        sessionStorage.setItem("tipo_barcode", tipo_barcode);
        this.$store.state.tipo_barcode = tipo_barcode;
      } else {
        this.logout();
      }
      this.checkJWT();
    } else if (this.$route.name != "LoginForm") {
      var storage = null
      if (
        sessionStorage.getItem("jwt") !== null &&
        sessionStorage.getItem("jwt") !== undefined &&
        sessionStorage.getItem("jwt") !== ""
      ) {
        storage = sessionStorage;
      }
      else if (
        localStorage.getItem("jwt") !== null &&
        localStorage.getItem("jwt") !== undefined &&
        localStorage.getItem("jwt") !== ""
      ) {
        storage = localStorage;
      } else {
        this.logout();
      }

      if(storage) {
        this.$store.state.jwt = storage.getItem("jwt");
        this.$store.state.documento =
          storage.getItem("documento") == null
            ? ""
            : storage.getItem("documento");
        this.$store.state.ambiente =
          storage.getItem("ambiente") == null
            ? ""
            : storage.getItem("ambiente");
        this.$store.state.tipo_barcode =
          storage.getItem("tipo_barcode") == null
            ? ""
            : storage.getItem("tipo_barcode");
        this.$store.state.cd_azienda = storage.getItem("cd_azienda");
      }

      this.checkJWT();
    }
    else {
      this.loaded = true;
    }
  },
  methods: {
    home() {
      // this.checkPage = true;
      router.push("/");
    },
    logout() {
      // localStorage.clear();
      localStorage.setItem("jwt", "")
      this.$store.state.jwt = "";

      if (
        this.$store.state.link_tracking !== null &&
        this.$store.state.link_tracking !== undefined &&
        this.$store.state.link_tracking !== "" && 
        localStorage.getItem("from_tracking") == "true"
      ) {
        window.location.href = `https://${this.$store.state.link_tracking}`;
      } else {
        router.push("/login-form")
        this.loaded = true
      }
    },
    tracking() {
      // localStorage.setItem("from_tracking", true)
      // this.logout()
      window.location.href = `https://${this.$store.state.link_tracking}`;
    },
    checkJWT() {
      return new Promise((resolve, reject) => {
        this.axios.defaults.headers.common["X-Auth-Token"] =
          this.$store.state.jwt;
        this.axios
          .get(`${this.$root.endpoint}jwt`)
          .then((response) => {
            this.$store.state.logo = response.data.logo;
            this.$store.state.background = response.data.background;
            this.$store.state.color_primary = response.data.color_primary;
            this.$store.state.color_secondary = response.data.color_secondary;
            this.$store.state.link_tracking = response.data.link_tracking;
            this.$store.state.cd_azienda = response.data.cd_azienda;
  
            localStorage.setItem("logo", response.data.logo);
            localStorage.setItem("background", response.data.background);
            localStorage.setItem("color_primary", response.data.color_primary);
            localStorage.setItem("color_secondary", response.data.color_secondary);
            localStorage.setItem("cd_azienda", response.data.cd_azienda);
  
            var index = response.data.ambienti.indexOf("Foto libera");
            if (index !== -1) {
              response.data.ambienti.splice(index, 1);
            } else {
              index = response.data.ambienti.indexOf("Foto Libera");
              if (index !== -1) {
                response.data.ambienti.splice(index, 1);
              }
            }
            this.$store.state.ambienti = response.data.ambienti;
  
            this.$vuetify.theme.themes.light.primary =
              response.data.color_primary;
            this.$vuetify.theme.themes.light.secondary =
              response.data.color_secondary;
  
            if (this.$route.name == "Login") {
              router.push("/");
            } else if (this.$route.name == "MailLogin") {
              router.push("/mail");
            }
  
            this.$nextTick(() => {
              this.loaded = true;
            })

            resolve();
          })
          .catch((error) => {
            console.log(error);
            this.logout();

            reject();
          });
      });
    },
    setToolbar() {
      this.show_toolbar = !["LoginForm", "Login", "MailLogin", "Mail"].includes(this.$route.name);
    }
  },
  watch: {
    $route: {
      handler(to) {
        if (this.$route.name == "Login" || this.$route.name == "MailLogin") {
          this.checkJWT();
        }
        document.title = to.meta.title || "Gestione foto mCLICK";
        
        if (this.$route.name == "Riepilogo") {
          this.currentPage = 0
        }

        this.setToolbar();
      }
    },
  },
  computed: {
    main_style: function () {
      var background = `background: url("${this.$store.state.background}") no-repeat fixed`
      var background_size = `background-size: contain;`

      //  Se lo sfondo deve stare al centro mettilo nell'array
      if(["calonigroupage", "nieddu", "malga"].includes(this.$store.state.cd_azienda.toLowerCase())){
        background += " center;"
      }
      else {
        background += " left;"
      }

      if (this.$route.name == "LoginForm") {
        background_size = `background-size: cover`
      }
      return `${background} ${background_size}`;
    },
  },
};
</script>

<style>
.x-button {
  position: absolute !important;
  z-index: 1;
  right: 6px;
  top: 6px;
}
</style>